
import loungeImg from '@/assets/images/lounge.webp'
import loungeImgMobile from '@/assets/images/heroes/mobile/lounge.webp'
import pinkCloudMobile from '@/assets/images/heroes/mobile/pink-cloud.webp'
import pinkCloud from '@/assets/images/pink-cloud.webp'
import pinkCloud2 from '@/assets/images/pink-cloud2.webp'
import { SectionText, SectionTitle } from '@/components/ui/text';

import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { cn } from '@/lib/utils';
import { useSections } from '@/components/section-provider';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import loungeVideo from '@/assets/animations/lounge.webm'
import { VideoAnimation } from '@/components/animations/video'

export const LizardLounge = () => {

    const { section } = useSections();

    const ref = useRef(null);

    const isInView = section === 5;

    // absolute right-0 lg:-top-[15%] bottom-0 lg:bottom-[unset] lg:h-[145%] z-0

    return (
        <motion.div ref={ref} key="lizlounge" className={cn('absolute inset-0 h-full w-full overflow-hidden', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <BrowserView>
                {/* <motion.div 
                    style={{ backgroundImage: `url(${loungeImg})`, backgroundSize: 'auto 100%', opacity: 0.5, content: ' ' }} 
                    className='block absolute w-full bg-no-repeat h-full z-0 bg-center lg:bg-right-top lg:-top-[15%] lg:h-[145%]'
                    {...slideProps("right", isInView)}
                /> */}
                <VideoAnimation
                    sectionIndex={5}
                    isInView={isInView}
                    src={loungeVideo}
                    className='block absolute h-full right-0 z-0 lg:-top-[15%] lg:h-[115%]'
                    {...slideProps("right", isInView)}
                />
                <motion.img 
                    src={pinkCloud} 
                    className='absolute left-0 bottom-0 w-full z-0' 
                    {...slideProps("left", isInView)}
                />
                <motion.img 
                    src={pinkCloud2} 
                    className='absolute left-0 bottom-0 lg:-bottom-20 w-full z-0' 
                    {...slideProps("left", isInView)}
                />
            </BrowserView>
            <MobileView>
                <motion.img 
                    src={loungeImgMobile} 
                    className='absolute right-0 lg:-top-[15%] -bottom-20 z-0' 
                    {...slideProps("right", isInView)}
                />
                <motion.img 
                    src={pinkCloudMobile} 
                    className='absolute left-0 bottom-0 w-full z-0' 
                    {...slideProps("left", isInView)}
                />
            </MobileView>
            <div className='relative mx-auto h-2/3 md:h-full max-w-[90%] lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='flex flex-col col-span-full md:col-span-3 gap-4 w-full text-left items-start'>
                    <div className='flex flex-col gap-4 justify-center'>
                        <SectionTitle isInView={isInView}>Lizard Lounge</SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[323px] lg:max-w-xl lg:text-base'>
                            Enter the player hub for our universe where you can earn more $LIZ and blue chip gaming tokens by completing Bounties.
                        </SectionText>
                    </div>
                    <motion.a 
                        className={cn(buttonVariants(), 'font-bold px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                        target='_blank'
                        href="https://lizlounge.io/"
                    >
                        {/* Become a Guardian of Gaming */}
                        Enter the Lounge
                    </motion.a>
                </div>
            </div>
        </motion.div>
    );
};