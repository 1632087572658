import logoIcon from '@/assets/images/logo-white.png';
import LizcoinHero from '@/components/sections/lizcoin/hero';
import Stars from '@/components/stars';
import { useConfig } from '@/hooks/use-config';
import { EthlizardsLayout } from '@/pages/ethlizards';
import { ArrowLeft } from 'lucide-react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

const LizcoinHeader = () => {

    return <header className="relative flex justify-between items-center w-full gap-4 h-20 lg:h-32 px-6 md:px-20 z-10">
        
        <div className="flex items-center gap-1.5 lg:gap-5">
            <img
                alt="Lizard Labs Logo Icon"
                src={logoIcon}
                className="h-[24px] w-[24px] lg:w-[34px] lg:h-[34px] logo"
            />
            <Link to="/" className="bg-black-gradient flex justify-center items-center gap-x-2.5 w-10 lg:w-auto lg:px-4 h-10 lg:h-12 rounded-[90px] text-white transition-all duration-200 hover:scale-[1.02]">
                <ArrowLeft />
                <span className='font-nekstbold text-lg !leading-5 hidden lg:block'>Back to Lizard Labs</span>
            </Link>
        </div>
        <div className="flex items-center md:gap-10">
            {/* <a href="#collections" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                Our Collections
            </a>
            <a href="https://merch.lizlabs.io/" target="_blank" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                Merch Store
            </a>
            <a href="#breakdown" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                NFT Breakdown
            </a> */}
            {/* <a 
                target="_blank" 
                href="https://blur.io/collection/ethlizards" 
                className="flex justify-center items-center h-[38px] lg:h-12 w-[123px] shadow-blur hover:shadow-blur-hover transition-all duration-300 rounded-[66px] border-[3px] border-[#FF6E26] hover:scale-[1.02]"
            >
                <img src={blurLogo} className="h-7" alt="Blur.io logo"/>
            </a> */}
        </div>
    </header>
}

function LizcoinPage() {

    ReactGA.send({
        hitType: 'pageview',
        page: "/lizcoin",
        title: 'Lizcoin'
    });

    const [config, setConfig] = useConfig();

    useEffect(() => {
        setConfig({
            ...config,
            theme: "black"
        })
    }, [])

    return (
        <EthlizardsLayout>
            
            <Stars className='absolute top-0 opacity-30 min-w-full h-dvh' />
            <LizcoinHeader />
            <main className="min-h-screen min-w-full">
                <div className='flex flex-col'>
                    <LizcoinHero />
                    <div className="absolute bottom-0 h-52 bg-fade-in-purple w-full z-[1]" style={{ backgroundImage: 'linear-gradient(0deg, #000 0%, #000 60.5%, rgba(20, 20, 24, 0.00) 100%)'}} />
                    <footer 
                        key="footer"
                        id="footer"
                        className='w-full'
                    > 
                        
                    </footer>
                </div>
            </main>
        </EthlizardsLayout>
    );
}
  
export default LizcoinPage;