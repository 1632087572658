import lizcoinImage from '@/assets/images/heroes/7.webp';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { motion, useInView } from 'framer-motion';
import Stars from '@/components/stars';
import { slideProps } from '@/components/animations/utils';
import { useRef } from 'react';

const LizcoinHero = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="lizcoin-hero"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 pt-20 md:pt-0 px-6 md:px-20 h-[calc(100vh-14rem)] md:h-[calc(100vh-8rem)]"
        >
            <div className='bg-eclipse absolute left-0 -top-[25%] h-[150%] w-[120%] rounded-full z-0' />
            <div className='relative flex flex-col justify-center items-start gap-y-8 z-[1]'>
                <div className='flex flex-col gap-y-[18px]'>
                    <SectionTitle isInView={true} className='text-[32px] lg:!text-[60px] !leading-9 lg:!leading-[64px]'>One token to<br/> unite all of gaming</SectionTitle>
                    <SectionText isInView={true} className='text-foreground font-nekstbold text-xl lg:text-[32px] !leading-5 lg:!leading-10 text-yellow'>
                        Unlock Possibilities with LizCoin
                    </SectionText>
                </div>
                <a href="#" className={cn(buttonVariants({ variant: "cta" }), '!h-12 text-[18.5px]')}>
                    Lizcoin CTA
                </a>
            </div>
            <div ref={ref} className='flex justify-center md:justify-end z-[1]'>
                <motion.img
                    src={lizcoinImage}
                    className='max-w-[325px] md:max-w-[425px] xl:max-w-[725px]'
                    {...slideProps("right", isInView)}
                />
            </div>
        </section>
    );
};

export default LizcoinHero;