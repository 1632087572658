import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Icons } from '@/components/ui/icons';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { useSections } from '@/components/section-provider';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';

interface TeamMember {
    name: string;
    title: string;
    linkedInUrl: string;
    twitterUrl: string;
    image: string;
    irlImage: string;
}

const team: TeamMember[] = [
    {
        name: "Animositas",
        title: "Chief Executive Officer",
        linkedInUrl: "https://www.linkedin.com/in/joe-ziska/",
        twitterUrl: "https://x.com/Ani_Knows",
        image: '/images/team/ani.png',
        irlImage: '/images/team/ani-irl.webp',
    },
    {
        name: "Lu",
        title: "Chief Operations Officer",
        linkedInUrl: "https://www.linkedin.com/in/lu-harvey-burchett-290b58135/",
        twitterUrl: "https://x.com/LuTheLizard",
        image: '/images/team/lu.png',
        irlImage: '/images/team/lu-irl.webp',
    },
    {
        name: "Archangel",
        title: "Chief Tech. Officer",
        linkedInUrl: "https://www.linkedin.com/in/matt-h-3946853/",
        twitterUrl: "https://x.com/intent/user?screen_name=lizlabsio",
        image: '/images/team/archangel.png',
        irlImage: '/images/team/archangel-irl.webp',
    },
    {
        name: "Michael",
        title: "Creative Lead",
        linkedInUrl: "https://www.linkedin.com/in/michael-lawless-555b034a/",
        twitterUrl: "https://x.com/pingujuice",
        image: '/images/team/mlawless.png',
        irlImage: '/images/team/michael-irl.webp',
    },
    {
        name: "Cladjules",
        title: "Lead Developer",
        linkedInUrl: "https://www.linkedin.com/in/londoncto",
        twitterUrl: "https://x.com/cladjules",
        image: '/images/team/cladjules.png',
        irlImage: '/images/team/cladjules-irl.webp',
    },
]

export const Team = () => {

    const { section } = useSections();

    const ref = useRef(null);

    const isInView = section === 8;

    return (
        <motion.div ref={ref} key="team" className={cn('absolute inset-0 h-full w-full overflow-hidden', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative h-full flex flex-col justify-center lg:items-center lg:text-center gap-y-3 md:gap-y-12 px-1.5'>
                <SectionTitle isInView={isInView} className='text-3xl lg:text-5xl text-foreground font-nekstbold !leading-[36px] 3xl:!leading-[80px]'>Meet the Minds <br/> Behind the Magic</SectionTitle>
                <SectionText isInView={isInView} className='text-foreground text-base lg:text-xl lg:max-w-xl !leading-6'>
                    Our DAO is supported by the top builders, investors, and creatives from across the world.
                </SectionText>
                
                <BrowserView>
                    <div className='flex justify-center w-full'>
                        <div className='relative grid grid-cols-3 lg:flex justify-center gap-y-6 lg:gap-y-0 gap-x-1.5 lg:gap-x-3 px-10 py-10 lg:py-0 lg:pb-14 bg-team rounded-[10px]'>
                            <div className='absolute top-[1px] bottom-[1px] left-[1px] right-[1px] bg-team-bg rounded-[10px]' />
                            {team.map((member, x) => (
                                <motion.div 
                                    key={member.name} 
                                    transition={{ duration: 1.5, bounceStiffness: 5, bounceDamping: 5, bounce: 0.4, type: 'spring', delay: x * 0.15 }}
                                    className='relative rounded-xl lg:w-[204px]'
                                    animate={isInView ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            opacity: 1,
                                            y: 0
                                        },
                                        hide: {
                                            opacity: 0,
                                            y: -80
                                        }
                                    }}
                                >
                                    <div className='relative flex flex-col justify-center items-center py-2 gap-y-2 lg:py-6 lg:gap-y-3 rounded-xl'>
                                        <div className='relative'>
                                            <img className='rounded-full h-20 w-20 lg:w-28 lg:h-28' src={member.irlImage} />
                                            <img className='absolute -top-2 -right-1.5 md:-top-4 md:-right-3 rounded-full h-8 w-8 lg:w-[52px] lg:h-[52px] border-[#5E78D8] border-2' src={member.image} />
                                        </div>
                                        <span className='text-white font-figtree text-base lg:text-xl leading-normal -mb-2'>{member.name}</span>
                                        <span className='flex items-center text-white/70 font-figtree text-xs lg:min-h-[unset] lg:text-sm text-ellipsis line-clamp-1 md:line-clamp-none'>{member.title}</span>
                                        <div className='flex gap-x-2 justify-center'>
                                            <Link to={member.linkedInUrl} target="_blank" className={cn(buttonVariants(), "text-sm h-8 w-8 bg-cyan hover:bg-cyan/90 lg:h-8")}>
                                                <Icons.linkedin className='w-[14px] h-[14px]' />
                                            </Link>
                                            {member.twitterUrl && (
                                                <Link to={member.twitterUrl} target="_blank" className={cn(buttonVariants(), "text-sm h-8 w-8 bg-cyan hover:bg-cyan/90 lg:h-8")}>
                                                    <Icons.twitterX className='w-[14px] h-[14px]' />
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <ScrollArea className="flex flex-col gap-y-3 w-full">
                        <div className="flex w-max gap-x-5 pt-4 px-6">
                            {team.map((member, x) => {
                                return <motion.div 
                                    key={`mob_team_${member.name}`} 
                                    className='relative bg-team rounded-[10px] px-5 pb-9 min-w-[189px]'
                                    transition={{ duration: 1.5, bounceStiffness: 5, bounceDamping: 5, bounce: 0.4, type: 'spring', delay: x * 0.15 }}
                                    animate={isInView ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            opacity: 1,
                                            y: 0
                                        },
                                        hide: {
                                            opacity: 0,
                                            y: -80
                                        }
                                    }}
                                >
                                    <div className='absolute top-[1px] bottom-[1px] left-[1px] right-[1px] bg-team-bg rounded-[10px]' />
                                    <div className='relative flex flex-col justify-center items-center py-2 gap-y-3 rounded-xl'>
                                        <div className='relative'>
                                            <img className='rounded-full w-28 h-28' src={member.irlImage} />
                                            <img className='absolute -top-4 -right-4 rounded-full w-[52px] h-[52px] border-[#5E78D8] border-2' src={member.image} />
                                        </div>
                                        <span className='text-white font-figtree text-xl leading-normal -mb-2'>{member.name}</span>
                                        <span className='flex items-center text-white/70 font-figtree text-sm text-ellipsis line-clamp-1'>{member.title}</span>
                                        <div className='flex gap-x-2 justify-center'>
                                            <Link to={member.linkedInUrl} target="_blank" className={cn(buttonVariants(), "text-sm h-8 w-8 bg-cyan hover:bg-cyan/90")}>
                                                <Icons.linkedin className='w-[14px] h-[14px]' />
                                            </Link>
                                            {member.twitterUrl && (
                                                <Link to={member.twitterUrl} target="_blank" className={cn(buttonVariants(), "text-sm h-8 w-8 bg-cyan hover:bg-cyan/90")}>
                                                    <Icons.twitterX className='w-[14px] h-[14px]' />
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>
                            })}
                        </div>
                        <div className='flex justify-center items-center h-8 w-full'>
                            <ScrollBar orientation="horizontal" className='h-1.5 bg-[#242424] rounded-[30px] max-w-[305px] mx-auto' />
                        </div>
                    </ScrollArea>
                </MobileView>
            </div>
        </motion.div>
    );
};