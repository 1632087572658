import { homeSections } from "@/registry/home-sections";
import { SectionProvider, useSections } from "@/components/section-provider";
import dotImg from "@/assets/images/dot.png";
import dotBlackImg from "@/assets/images/dot-black.png";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDown, ArrowUp } from "lucide-react";
import { useCallback, useEffect, useRef } from "react";
import Footer from "@/components/footer";
import { GuardiansOfGaming } from "@/components/sections/home/guardians-of-gaming";
import { ReadyPlayerFun } from "@/components/sections/home/ready-player-fun";
import { BattleInTheBeyond } from "@/components/sections/home/battle-in-the-beyond";
import { ElementalLizardsPortal } from "@/components/sections/home/elemental-lizards-portal";
import { Ethlizards } from "@/components/sections/home/ethlizards";
import { LizardLounge } from "@/components/sections/home/lizard-lounge";
import { LizardLaunchpad } from "@/components/sections/home/lizard-launchpad";
import { FollowUs } from "@/components/sections/home/follow-us";
import { Team } from "@/components/sections/home/team";
import { Header } from "@/components/navigation/header";
import WheelReact from 'wheel-react';
import { useSwipeable } from "react-swipeable";
import ReactGA from 'react-ga4';
import { Lizcoin } from "@/components/sections/home/lizcoin";

const HomeLayout = ({ children }: { children: React.ReactElement }) => {
    return <div className="relative home-page flex h-dynamic w-full flex-col items-center overflow-hidden">
        <Header/>
        {children}
    </div>
}

const SectionDots = () => {

    const { section, moveSection } = useSections();

    const sec = homeSections[section];

    const variants = {
        active: {
            width: 20,
            height: 20
        },
        neighbour: {
            width: 14,
            height: 14
        },
        dot: {
            width: 8,
            height: 8
        }
    }

    return (
        <div className="hidden lg:flex flex-col items-center justify-center fixed left-12 h-full z-10 -mt-10 w-10">
            {homeSections.map((s, x) => {
                const categoryActive = section === x;
                const isNeighbour = section-1 === x || section+1 === x;
                const opacity = categoryActive || isNeighbour ? 1 : Math.abs(1 / (x - section));
                return <div id={`btn_moveto_${sec.id}`} key={`cat_${s.id}`} className="relative rounded-lg transition-all duration-300 py-1 px-2 cursor-pointer" onClick={() => moveSection(0, x)}> 
                    <motion.img 
                        src={dotImg} 
                        className={cn("transition-all duration-300 color-dot")} 
                        variants={variants}
                        animate={categoryActive ? 'active' : isNeighbour ? 'neighbour' : 'dot'}
                        style={{ opacity }} 
                        
                    />
                    <motion.img 
                        src={dotBlackImg} 
                        className={cn("transition-all duration-300 black-dot")} 
                        variants={variants}
                        animate={categoryActive ? 'active' : isNeighbour ? 'neighbour' : 'dot'}
                        style={{ opacity }} 
                    />
                </div>
            })}
        </div>
    )
}

const SectionArrows = () => {

    const { section, moveSection } = useSections();

    const backDisabled = section === 0;
    const forwardDisabled = section === homeSections.length-1;
    

    useEffect(() => {
        const keyPress = (event: KeyboardEvent) => {
            if (event.code === "ArrowDown" || event.code === "ArrowRight") {
                moveSection(1);
            } else if (event.code === "ArrowUp" || event.code === "ArrowLeft") {
                moveSection(-1);
            }
        }

        window.addEventListener("keydown", keyPress);

        return () => window.removeEventListener("keydown", keyPress);
    }, [section, moveSection])
  
    return <div className="flex gap-1 fixed right-6 bottom-6 z-10">
        <Button 
            id="section_back"
            type="button"
            variant="ghost"
            onClick={(e) => {
                e.preventDefault();

                moveSection(-1);
            }}
            className={cn("relative rounded-full bg-arrows w-14 h-14 lg:w-10 lg:h-10 hover:scale-105 transition-all duration-150", backDisabled && "hidden")}
        >
            <ArrowUp className={cn("absolute h-8 w-8 lg:h-6 lg:w-6 text-white", backDisabled && "hidden")} />
        </Button>
        <Button 
            id="section_next"
            type="button"
            variant="ghost" 
            onClick={(e) => {
                e.preventDefault();

                moveSection(1);
            }}
            className="relative rounded-full bg-arrows w-14 h-14 lg:w-10 lg:h-10 hover:scale-105 transition-all duration-150"
        >
            <ArrowDown className={cn("absolute h-8 w-8 lg:h-6 lg:w-6 text-white", forwardDisabled && "opacity-70")} />
        </Button>
    </div>
}

export const SectionContent = () => {

    const { moveSection, showFooter, setShowFooter } = useSections();

    const isAnimatingRef = useRef(false);

    const move = useCallback((dir: number) => {
        if (isAnimatingRef.current) return;
        if (showFooter && dir < 0) {
            setShowFooter(false);
            return;
        }

        moveSection(dir);

        setTimeout(() => {
            isAnimatingRef.current = false;
        }, 1200);
    }, [isAnimatingRef.current, moveSection, showFooter]);

    const handlers = useSwipeable({
        delta: 5,
        onSwipedDown: () => {
            move(-1)
        },
        onSwipedUp: () => move(1)
    });

    WheelReact.config({
        up: () => {
            move(1);
        },
        down: () => {
            move(-1);
        }
    });

    return <div 
        id="home_sections"
        className="w-full h-full overflow-hidden"
        {...WheelReact.events}
        {...handlers}
    >
        <AnimatePresence>
            <GuardiansOfGaming key="GuardiansOfGaming" />
            <ReadyPlayerFun key="ReadyPlayerFun" />
            <BattleInTheBeyond key="BattleInTheBeyond" />
            <ElementalLizardsPortal key="ElementalLizardsPortal" />
            <Ethlizards key="Ethlizards" />
            <LizardLounge key="LizardLounge" />
            <LizardLaunchpad key="LizardLaunchpad" />
            {/* <Lizcoin key="Lizcoin" /> */}
            <FollowUs key="FollowUs" />
            <Team key="Team" />
        </AnimatePresence>
    </div>
}

export const HomePage = () => {

    ReactGA.send({
        hitType: 'pageview',
        page: "/",
        title: 'Home'
    });

    useEffect(() => {
        document.body.classList.add("overflow-hidden");

        return () => {
            document.body.classList.remove("overflow-hidden");
        }
    }, []);

    return (
        <SectionProvider>
            <HomeLayout>
                <>
                <SectionDots />
                <SectionArrows />
                <main className="relative w-full h-full z-[1]">
                    <SectionContent />
                </main>
                <Footer />
                </>
            </HomeLayout>
        </SectionProvider>
    );
}