import { slideProps } from "@/components/animations/utils";
import { useSections } from "@/components/section-provider";
import { ForwardRefComponent, HTMLMotionProps, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

interface VideoAnimationType extends HTMLMotionProps<"video"> {
    isInView: boolean;
    src: string;
    className: string;
    sectionIndex: number;
}

export const VideoAnimation = ({
    isInView,
    src,
    sectionIndex,
    ...props
}: VideoAnimationType) => {

    const { section } = useSections();

    const videoRef = useRef<HTMLVideoElement>(null);

    const [loaded, setLoaded] = useState(false);

    const loadVideo = isInView || ((section-1 === sectionIndex) || (section+1 === sectionIndex))

    useEffect(() => {
        if (videoRef?.current && loaded) {
            try {
                if (isInView) {
                    videoRef.current.play();
                } else {
                    videoRef.current.pause();
                }
            } catch(e) {
                console.log(e);
            }
        }
    }, [isInView, loaded])

    return (!loadVideo && !loaded) ? null : (
        <motion.video 
            ref={videoRef}
            poster={undefined}
            autoPlay={false}
            loop
            muted
            playsInline
            onLoadedData={() => {
                setLoaded(true);
            }}
            {...slideProps("right", isInView)}
            {...props}
        >
            <source src={src} type="video/webm" />
        </motion.video>
    );
};