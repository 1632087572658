
import { SectionText, SectionTitle } from '@/components/ui/text';

import lizardImg from '@/assets/images/heroes/5.webp'
import lizardImgMob from '@/assets/images/heroes/mobile/pepe.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { cn } from '@/lib/utils';
import { useSections } from '@/components/section-provider';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import pepeVideo from '@/assets/animations/pepe.webm'
import { VideoAnimation } from '@/components/animations/video';

export const Ethlizards = () => {

    const { section } = useSections();

    const ref = useRef(null);

    const isInView = section === 4;
    
    return (
        <motion.div ref={ref} key="ethl" className={cn('absolute inset-0 h-full w-full', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <BrowserView>
                {/* <motion.div 
                    style={{ backgroundImage: `url(${lizardImg})`, backgroundSize: 'auto 100%', opacity: 0.5, content: ' ' }} 
                    className='block absolute w-full bg-no-repeat h-full z-0 bg-center lg:bg-right-top'
                    {...slideProps("right", isInView)}
                /> */}
                <VideoAnimation
                    sectionIndex={4}
                    isInView={isInView}
                    src={pepeVideo}
                    className='block absolute h-full z-0 right-0'
                    {...slideProps("right", isInView)}
                />
            </BrowserView>
            <MobileView>
                <motion.img 
                    src={lizardImgMob} 
                    className='absolute left-0 bottom-0 w-full z-0' 
                    {...slideProps("left", isInView)}
                />
            </MobileView>
            <div className='relative mx-auto h-4/5 lg:h-full max-w-[90%] lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='flex flex-col col-span-full md:col-span-3 gap-4 w-full text-left items-start'>
                    <div className='flex flex-col gap-4 justify-center'>
                        <SectionTitle isInView={isInView}>Ethlizards</SectionTitle>
                        <SectionText isInView={isInView} className='md:max-w-xl lg:text-base'>
                            From our origins as a quirky NFT collection, to becoming a cornerstone of Web3 gaming, 
                            Ethlizards stand as a beacon of light in a sea of seriousness, a community where gamers, builders, 
                            and investors unite under the banner of memes and industry leading utility. 
                            Ethlizards holders get to reap many benefits including airdrops, staking rewards from portfolio liquidations, launchpad opportunities, 
                            high quality alpha, top whitelists and much more!
                            {/* <ul className='list-disc list-inside mt-6'>
                                <li>Lizcoin ($LIZ) Airdrops</li>
                                <li>Elemental Lizard Airdrops</li>
                                <li>Staking Bounty Rewards</li>
                                <li>Lizard Launchpad Priority Access</li>
                                <li>Discord Alpha and Degen Channels</li>
                                <li>Top Quality White Lists and Free Mints</li>
                                <li>Governance Voting</li>
                            </ul> */}
                        </SectionText>
                    </div>
                    <motion.a 
                        href='/ethlizards'
                        className={cn(buttonVariants(), 'font-bold mt-2 px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                    >
                        Become a Lizard
                    </motion.a>
                </div>
            </div>
        </motion.div>
    );
};