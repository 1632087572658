import { BattleInTheBeyond } from "@/components/sections/home/battle-in-the-beyond";
import { ElementalLizardsPortal } from "@/components/sections/home/elemental-lizards-portal";
import { Ethlizards } from "@/components/sections/home/ethlizards";
import { FollowUs } from "@/components/sections/home/follow-us";
import { GreenRoom } from "@/components/sections/home/green-room";
import { GuardiansOfGaming } from "@/components/sections/home/guardians-of-gaming";
import { LizardLaunchpad } from "@/components/sections/home/lizard-launchpad";
import { LizardLounge } from "@/components/sections/home/lizard-lounge";
import { Lizcoin } from "@/components/sections/home/lizcoin";
import { ReadyPlayerFun } from "@/components/sections/home/ready-player-fun";
import { Team } from "@/components/sections/home/team";
import { Theme } from "@/registry/themes";

export interface PageSection {
    id: string;
    theme: Theme["name"];
    component: () => JSX.Element;
    title: string;
}

export const homeSections: PageSection[] = [
    {
        id: "guardians",
        theme: "dark-purple",
        component: GuardiansOfGaming,
        title: "Guardians of Gaming"
    },
    {
        id: "readyplayer",
        theme: "cyan",
        component: ReadyPlayerFun,
        title: "Ready Player Fun"
    },
    {
        id: "bitb",
        theme: "dark-purple",
        component: BattleInTheBeyond,
        title: "Battle in the Beyond"
    },
    {
        id: "elemental",
        theme: "light-purple",
        component: ElementalLizardsPortal,
        title: "Elemental Lizards"
    },
    {
        id: "ethlizards",
        theme: "dark-lightblue",
        component: Ethlizards,
        title: "Ethlizards"
    },
    // {
    //     id: "greenroom",
    //     theme: "black",
    //     component: GreenRoom
    // },
    {
        id: "lounge",
        theme: "dark-lightblue",
        component: LizardLounge,
        title: "Lizard Lounge"
    },
    {
        id: "launchpad",
        theme: "green",
        component: LizardLaunchpad,
        title: "Launchpad"
    },
    // {
    //     id: "lizcoin",
    //     theme: "lizcoin",
    //     component: Lizcoin,
    //     title: "Lizcoin"
    // },
    {
        id: "followus",
        theme: "black",
        component: FollowUs,
        title: "Follow Us"
    },
    {
        id: "team",
        theme: "black",
        component: Team,
        title: "The Team"
    }
];